.PlatformLogo {
    display: block;
    position: relative;
    width: auto;
    max-height: 100%;

    svg {
        width: auto;
        height: 2.5em;

        [data-theme='gp'] & {
            height: 3em;
        }
    }

    &.is-mini {
        display: inline-block;

        svg {
            height: 1em;
        }

        svg path {
            fill: rgb(var(--lighterTextColor));
        }
    }

    &.extra-padding {
        margin-bottom: var(--standardPadding);
    }

    &.has-background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4em;
        height: 4em;
        border-radius: var(--standardRadius);
        background-color: rgb(var(--mainColor));

        svg {
            height: 1.375em;
        }
    }
}
