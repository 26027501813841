@use 'mixins';

$transition: all 0.25s ease-in-out;

.Navigation {
    display: flex;
    align-items: center;
    flex: 1;
    flex-flow: row wrap;
    position: relative;
    height: inherit;

    [data-theme='gp'] & {
        text-transform: uppercase;
    }

    [data-theme='br'] & {
        font-family: var(--heading-font-family);
    }

    nav {
        transition: height 0.25s ease-in-out;

        ul {
            display: flex;

            li {
                position: relative;

                a {
                    display: block;
                    opacity: 0.8;
                    padding: var(--largePadding) var(--standardPadding);
                    font-weight: 600;
                    color: rgb(var(--invertedTextColor));
                    text-decoration: none;

                    &:hover {
                        opacity: 1;
                        transition: $transition;
                    }
                }

                &.is-active a {
                    opacity: 1;
                    font-weight: 700;
                }
            }
        }
    }

    .hamburger-area {
        display: inline-flex;
        justify-content: flex-start;
        order: 0;
        flex: 1;
        max-width: calc(100% / 3);

        [data-theme='vi'] & {
            display: none;
        }
    }

    .logo-area {
        display: inline-flex;
        position: relative;
        justify-content: center;
        order: 1;
        width: calc(100% / 3);

        [data-theme='vi'] & {
            margin-left: var(--standardPadding);
            width: auto;
            order: 0;
        }
    }

    .search-area {
        display: none;
        order: 5;
    }

    .settings-area {
        display: none;
    }

    &.light-background nav li {
        &.is-active a {
            color: rgb(var(--textColor));
        }

        a {
            color: rgb(var(--lighterTextColor));

            &:hover {
                transition: $transition;
                color: rgb(var(--textColor));
            }
        }
    }

    @include mixins.responsive(l, below) {
        padding-top: var(--smallPadding);

        nav {
            opacity: 0;
            height: 0;
            overflow: hidden;
            order: 4;
        }

        &.fullscreen {
            nav {
                order: 3;
                opacity: 1;
                width: 100%;
                height: calc(100vh - 5em);
                margin: var(--standardPadding);

                ul {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    margin: 0 0 auto;
                }

                li {
                    display: block;
                    height: 100%;
                    padding: 0.375em var(--standardPadding);

                    a {
                        padding: var(--standardPadding);
                    }

                    &.is-active {
                        [data-theme='br'] &,
                        [data-theme='gp'] & {
                            border-bottom: 0;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: var(--standardPadding);
                                width: 3px;
                                height: 1.125em;
                                background-color: rgb(var(--mainColor));
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }

            .settings-area {
                display: block;
                position: absolute;
                bottom: var(--hugePadding);
                left: var(--largePadding);
            }

            .search-area {
                display: flex;
                order: 2;
                width: 100%;
                margin: var(--largePadding) var(--standardPadding);
                margin-bottom: 0;
                border: 1px solid rgba(var(--lightSectionBorder), 0.3);
                color: rgb(var(--invertedTextColor));
                border-radius: var(--standardRadius);
            }
        }

        .pro {
            padding-top: 0 !important;

            :global(.Icon) {
                font-size: 3em;
            }
        }
    }

    @include mixins.responsive(l, above) {
        flex-flow: nowrap;
        margin: 0 auto;

        nav {
            display: flex;
            flex-direction: row;
            order: 1;
            flex: 1;
            height: 100%;
            padding-left: var(--standardPadding);
            margin-left: var(--standardPadding);

            &.collapsed {
                z-index: 0;
                opacity: 0;
                overflow: hidden;
                width: 0;
                height: 100%;
                transition: width 0.25s ease-in-out;
            }

            ul {
                flex-direction: row;
                height: 100%;
            }

            li {
                display: flex;
                align-items: center;
                margin-left: var(--standardPadding);
                vertical-align: middle;

                &.is-active {
                    [data-theme='br'] &,
                    [data-theme='gp'] & {
                        height: calc(100% - 2px);
                        border-bottom: 2px solid rgb(var(--mainColor));
                        border-left: 0;
                    }
                }
            }
        }

        .hamburger-area {
            display: none;
        }

        .logo-area {
            display: block;
            order: 0;
            width: auto;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: calc(-1 * var(--largePadding));
                width: 2px;
                height: 2.25em;
                background-color: rgba(var(--sectionBorder), 0.2);
                transform: translateY(-50%);

                [data-theme='br'] & {
                    background-color: rgb(var(--sectionBorder));
                }
            }
        }

        .settings-area {
            [data-theme='gp'] & {
                display: block;
                order: 2;
                position: relative;
            }
        }

        .search-area {
            display: flex;
            order: 2;
            position: relative;

            &.expanded {
                z-index: 1;
                opacity: 1;
                transition: width 0.25s ease-in-out;
            }
        }
    }
}
