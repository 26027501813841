@use 'mixins';

$-line-height: 1.5em;

.LinkList {
    .list-item {
        display: block;
        margin: var(--smallPadding) 0;
        line-height: $-line-height;
        list-style-type: none;
        list-style-image: none;
    }

    a {
        @extend %link-medium;

        text-decoration: none;
        color: rgb(var(--invertedTextColor));

        &:hover {
            color: rgb(var(--invertedTextColor));
            text-decoration: underline;
        }
    }

    &.emphasized {
        .list-item {
            margin: initial;

            a {
                text-decoration: underline;
                color: rgb(var(--mainColor));

                [data-theme='vp'] &,
                [data-theme='vn'] & {
                    color: rgb(var(--linkColor));
                }
            }
        }
    }
}
