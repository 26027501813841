@use 'mixins';

.GridWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        'branding'
        'about'
        'social'
        'trending'
        'contact';

    @include mixins.responsive(m, above) {
        gap: var(--standardPadding);
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            'branding social'
            'about trending'
            'contact trending';

        .grid-item {
            padding-right: initial;
            padding-left: initial;
        }
    }

    @include mixins.responsive(l, above) {
        grid-template-columns: repeat(4, minmax(auto, 1fr));
        grid-template-areas:
            'branding about trending contact'
            'branding social trending contact';
    }
}
