@use 'mixins';

$-bg-default: linear-gradient(to right, rgb(var(--mainColor)), rgb(var(--mainColorLighter)));
$-bg-gp: rgb(var(--invertedBackground)) url('https://gp.cdn.pxr.nl/images/gp/bg/menu-image-gp.jpg') no-repeat
    right top;
$-bg-light: rgb(var(--background));
$headerHeight: 4em;
$headerHeightDesktop: 5em;
$iconSize: 1.5em;
$transition: all 0.25s ease-in-out;

.Header {
    position: relative;
    padding: inherit var(--standardPadding);
    box-sizing: border-box;
    transition: $transition;
    box-shadow: rgba(var(--invertedBackground), 0.08) 0 0 10px 0;

    .top-container {
        display: contents;
        height: inherit;
        min-height: inherit;
    }

    .top {
        position: relative;
        background: $-bg-default;
        min-height: $headerHeight;

        [data-theme='gp'] & {
            background: $-bg-gp;
        }

        [data-theme='br'] &,
        [data-theme='vi'] & {
            background: $-bg-light;
            border-bottom: 1px solid rgb(var(--sectionBorder));

            :global(.Icon) {
                color: rgb(var(--lighterTextColor));
            }
        }
    }

    @include mixins.responsive(l, above) {
        display: flex;
        flex-direction: column;
        position: relative;

        .top-container {
            display: flex;
        }

        .top {
            display: flex;
            flex-flow: row nowrap;
            flex-flow: nowrap;
            justify-content: space-between;
            align-items: center;
            flex: 100%;
            min-height: $headerHeightDesktop;
            overflow: visible;

            [data-theme='gp'] & {
                background-size: 27em;
            }

            &.national-theme {
                background: linear-gradient(
                    to right,
                    rgb(var(--mainNationalColor)),
                    rgb(var(--secondaryNationalColor))
                );
            }
        }

        .bottom {
            display: none;

            [data-theme='gp'] &,
            [data-theme='br'] &,
            [data-theme='vi'] & {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex: 100%;
                position: sticky;
            }
        }
    }
}
